import React, { PureComponent } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { reduxForm } from "redux-form";
import "../../scss/component/navbar.scss";
import { withTranslation } from "react-i18next";
import { NotificationContainer } from "react-notifications";
import CardTitle from "reactstrap/lib/CardTitle";
import Table from "./components/table";
import axios from "axios";

class Prover extends PureComponent {
  render() {
    return (
      <div className="content">
        <Container style={{ marginLeft: "0px" }}>
          <NotificationContainer />

          <Row>
            <Card>
              <CardBody style={{ height: "auto", width: "auto" }}>
                {/* <Col>
                  <CardTitle className="card__title">
                    Баталгаажуулах бүртгэлүүд
                  </CardTitle>
                  <hr />
                </Col> */}
                {/* <MaterialTable /> */}

                <Table />
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    );
  }
}

export default reduxForm({
  form: "vertical_form", // a unique identifier for this form
})(withTranslation("common")(Prover));
